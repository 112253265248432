body {
  font-family: 'Outfit';
  font-weight: 300;
  background-color: #FAFAFA;
}
.outside-app {
  background-color: #FFFFFF;
}
.navtop {
  /*-webkit-box-shadow: 0px 1px 1px #e8e8e8;
  -moz-box-shadow: 0px 1px 1px #e8e8e8;
  box-shadow: 0px 1px 1px #e8e8e8;*/
  border-bottom: 1px solid #EAEEF5;
  left: 238px;
  height: 74px;
  transition: left 0.5s;
}
.brand-logo {
  height: 40px;
  margin: 47px 0;
}
.bg-dark {
  background-color: rgba(17,17,17,100) !important;
}
.sa-panel__header {
  display: none;
}

.noScroll {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.main {
  align-items: stretch;
  display: flex;
  width: 100%;
  margin-top: 75px;
  padding: 40px;
}
.onboarding {
  padding: 0;
  .container-fluid {
    padding: 0;
  }
}
.logged-out {
  display: block;
}
.loggedOut-content {
  min-height: calc(100vh - 72px);
  width: 100%;
}
.logged-in {
  display: flex;
}
.billing-btn {
  font-weight: 100;
  padding: 8px;
}
.billing-btn:hover {
  border-radius: 8px;
}
.pub-page {
  margin-top: 0;
  padding: 0;
  .navbar-main {
    font-size: 1.8rem;
    position: relative;
    z-index: 5;
    .navbar-content {
      --links-gap: calc(.75 * 16px);
      font-family: freight-sans-pro,sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: calc(1 * 16px);
      height: calc(4.5 * 16px);
      letter-spacing: 0;
      position: relative;
      .navbar-grid {
        --box-shadow: 0;
        align-items: center;
        background: #fff;
        box-shadow: 0 0 calc(0*calc(1.125 * 16px)) rgba(0, 0, 0, 0.15);
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        height: calc(4.5 * 16px);
        left: 0;
        padding: 0 calc(.9375 * 16px);
        position: fixed;
        right: 0;
        top: 0;
        transition: box-shadow .12s ease-in-out,background .15s ease-in-out;
        .logo {
          color: #2b2a35;
          display: flex;
          height: 25px;
          transition: color .15s ease-in-out;
        }
        .nav-right {
          align-items: center;
          display: grid;
          gap: calc(.625 * 16px);
          grid-auto-flow: column;
          a {
            all: unset;
            cursor: pointer;
            white-space: nowrap;
            align-items: center;
            border: none;
            border-radius: 6px;
            display: inline-flex;
            font-size: calc(1 * 16px);
            font-weight: 700;
            height: calc(2.625 * 16px);
            justify-content: center;
            letter-spacing: 0;
            line-height: 1.1;
            overflow: hidden;
            padding: 0 1.5em;
            position: relative;
            text-align: center;
            text-decoration: none;
            color: white;
            background-image: linear-gradient(90deg, #4A60F4, #0500FF85);
            font-family: freight-sans-pro,sans-serif;
            font-style: normal;
          }
          @media screen and (max-width: 39.9375em) {
            a {
              padding: 0 1em;
            }
          }
          .login-btn {
            background-image: linear-gradient(90deg, #ffffff, #ffffff);
            color: #4960F3;
            border: 1px solid #4960F3;
          }
          .demo-btn {
            --position-x: 0%;
            transform: translateZ(0);
          }
          .mobile-nav {
            -webkit-appearance: button;
            all: unset;
            cursor: pointer;
            white-space: nowrap;
            --duration: .15s;
            align-items: center;
            display: flex;
            flex-direction: column;
            height: calc(1.5 * 16px);
            justify-content: center;
            position: relative;
            transition: transform calc(.15s + .15s*.5) ease-in;
            width: calc(1.5 * 16px);
            will-change: transform;
            span {
              display: block;
            }
            span:before {
              background-color: #2b2a35;
              border-radius: 1px;
              content: "";
              display: block;
              height: calc(.125 * 16px);
              width: calc(1.5 * 16px);
            }
            span:first-child {
              transition: transform .15s ease-out .15s;
              will-change: transform;
              transform: translateY(calc(-.375 * 16px));
            }
            span:nth-child(2) {
              margin: calc(-.125 * 16px) 0;
              transition: opacity 0s .15s;
              will-change: opacity;
            }
            span:nth-child(3) {
              transition: transform .15s ease-out .15s;
              will-change: transform;
              transform: translateY(calc(.375 * 16px));
            }
          }
          .mobile-nav-open {
            transform: rotate(45deg);
            transition-delay: calc(.15s*.5);
            transition-timing-function: ease-out;
            span:first-child {
              transform: translateY(0);
            }
            span:nth-child(2) {
              opacity: 0;
            }
            span:nth-child(3) {
              transform: translateY(0);
            }
            span:nth-child(3):before {
              transform: rotate(90deg);
              transition-delay: .15s;
              transition-timing-function: ease-out;
            }
          }
        }
        .nav-middle {
          font-size: calc(1.125 * 18px);
          line-height: 1.3;
          .nav-items-middle {
            margin: 0;
            padding: 0;
            li {
              list-style: none;
            }
            .menu-item {
              text-decoration: none;
              color: #121212;
            }
            .menu-item:hover {
              color: #4a60f4;
            }
          }
        }
      }
      .nav-scrolled {
        box-shadow: 0 0 calc(1*calc(1.125 * 16px)) rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.flow-screen {
  padding: 0;
  #wrapper {
    .wrapper-content {
      padding: 0;
    }
  }
}

@media (min-width: 70em) {
  .pub-page {
    .navbar-main {
      .navbar-content {
        .navbar-grid {
          grid-template-columns: auto auto auto;
          .nav-right {
            gap: calc(.9375 * 16px);
            .mobile-nav {
              display: none;
            }
          }
          .nav-middle {
            grid-column: 2;
            justify-self: center;
            position: relative;
            .nav-items-middle {
              display: inline-grid;
              gap: calc(2.5 * 16px);
              grid-auto-flow: column;
              .menu-item {
                align-items: center;
                display: flex;
                height: calc(4.5 * 16px);
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 90em) {
  .pub-page {
    .navbar-main {
      .navbar-content {
        .navbar-grid {
          grid-template-columns: 1fr auto 1fr;
          padding: 0 calc(1.875 * 16px);
        }
      }
    }
  }
}

@media not all and (min-width: 70em) {
  .main {
    padding: 0;
  }
  .pub-page {
    .navbar-main {
      .navbar-content {
        .navbar-grid {
          .nav-middle {
            background: #fff;
            color: #2b2a35;
            height: calc(100% - calc(3.75 * 16px));
            left: 0;
            opacity: 0;
            overflow-y: scroll;
            padding: calc(calc(2.5 * 16px) - calc(.75 * 16px)) calc(2.5 * 16px);
            pointer-events: none;
            position: fixed;
            top: calc(3.75 * 16px);
            transform: translateY(calc(-.3125 * 16px));
            width: 100%;
          }
          .nav-middle-mobile {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);
            transition-duration: .3s;
            .nav-items-middle {
              li {
                margin: calc(.75 * 16px) 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 70em) {
  .pub-page {
    .navbar-main {
      .navbar-content {
        .navbar-grid {
          .logo {
            grid-column: 1;
            justify-self: start;
          }
          .nav-right {
            grid-column: 3;
            justify-self: end;
          }
        }
      }
    }
  }
}

.progress-bar {
  background-color: #4A60F4;
}
.sidebar-large .sidebar {
  min-width: 238px;
  max-width: 238px;
  transition: min-width 0.5s, max-width 0.5s;
}
.sidebar-small .sidebar {
  min-width: 75px;
  max-width: 75px;
  transition: min-width 0.5s, max-width 0.5s;
}
.sidebar-small .sidebar-item {
  text-align: center;
}
.sidebar-small .sidebar-link.active, .sidebar-small .sidebar-link {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-small .sidebar-link img {
  margin-right: 0 !important;
  width: 20px;
  height: 20px;
}
.sidebar-small .sidebar-link span {
  display: none;
}
.sidebar-small .sidebar-toggle {
  left: 60px;
  transition: left 0.5s;
}
.sidebar-small .sidebar-toggle img {
  transform: rotate(180deg);
}
.sidebar-small #wrapper {
  padding-left: 75px;
  transition: margin-left 0.5s;
}
.sidebar-small .navtop {
  left: 75px;
  transition: left 0.5s;
}
.navtop.full-width {
  left: 0;
}
.sidebar {
  min-width: 238px;
  max-width: 238px;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
  direction: ltr;
  background: #1e2228;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
}
.sidebar .profile-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.625rem 1.625rem 2.5rem;
  align-items: center;
}
.sidebar-toggle {
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(16, 16, 19, 0.14);
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 225px;
  top: 22px;
  cursor: pointer;
  z-index: 1031;
  transition: left 0.5s;
}
.sidebar-toggle img {
  max-height: 50%;
}
.chat-notification {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  img {
    height: 25px;
    margin: 0 10px;
  }
  .new-messages {
    background: #E86060;
    border: 2px solid #FFFFFF;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    right: 8px;
  }
}
#profile-dropdown {
  color: #151D2C;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.dropdown-menu {
  width: 260px;
  padding: 20px;
  a:hover {
    background-color: #e8e8e8;
    border-radius: 8px;
  }
}
.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.dropdown-arrow {
  transform: rotate(-90deg);
  margin-left: 10px;
}
.profile-section img {
  width: 65px;
  height: 65px;
}
.user-info {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-info .username {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 0;
  word-break: break-all;
}
.user-status {
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 200;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-status span {
  width: 8px;
  height: 8px;
  border-radius: 7px;
  background-color: green;
  display: inline-block;
  margin-right: 8px;
}
.sidebar-content {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
  background: #1e2228;
}
.ps {
  overflow-y: hidden;
  touch-action: auto;
}
.scrollbar-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.sidebar-nav {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 1rem;
}
.sidebar-item {
  padding: 0 10px;
}
.sidebar-link.inactive {
  opacity: 0.3;
}
.sidebar-link .coming-soon {
  font-size: 10px;
  padding-left: 3px;
}
.sidebar-link, a.sidebar-link, .logout-btn {
  display: block;
  padding: 20px;
  font-weight: 400;
  transition: color 75ms ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  color: #8F97A6;
  font-weight: 300;
}
a.sidebar-link .nav-icon, .logout-btn img {
  margin-right: 0.75rem;
  opacity: 0.6;
}
a.active {
  /*opacity: 1;
  background-color: #6db9b7;
  color: #ffffff;*/
  background: #283145;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-weight: 500;
}
a.active img {
  opacity: 1 !important;
}
.logout-ul {
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 42px;
  .upgrade-badge {
    // background: rgba(255, 255, 255, 0.1);
    background-image: url('./images/upgradeBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // border-radius: 15px;
    margin: 0 7px;
    .upgrade-inner {
      padding: 15px;
      color: #fff;
      .upgrade-title {
        font-weight: 700;
        font-size: 26px;
        line-height: 53px;
        text-align: center;
        color: rgba(255, 255, 255, 0.9);
      }
      .upgrade-text {
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
      }
      .free-tracker {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        // .free-label {
        //   background: #FFFFFF;
        //   border-radius: 20px;
        //   font-size: 14px;
        //   line-height: 21px;
        //   color: rgba(0, 0, 0, 0.6);
        //   padding: 4px 20px;
        //   margin-bottom: 10px;
        // }
        .free-progress {
          font-weight: 700;
          font-size: 22px;
          line-height: 35px;
          color: #FFFFFF;
        }
      }
      .upgrade-cta {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .upgrade-btn {
          background: #4A60F4;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: rgba(255, 255, 255, 0.9);
          text-decoration: none;
          padding: 7px 15px;
        }
      }
    }
  }
}
.dropdown-toggle:after {
  display: none;
}
.feather {
  width: 18px;
  height: 18px;
}

#wrapper {
  padding-left: 238px;
}

#wrapper.full-width {
  padding-left: 0;
}

.wrapper-content {
  // height: 100%;
}
.grey-bg {
  background-color: #ecedf0;
}

.nav-breadcrumb {
  padding: 10px 15px;
  background-color: #f5f5f5;
  font-weight: 200;
}
.nav-breadcrumb ol {
  margin-bottom: 0;
  align-items: center;
}
.nav-breadcrumb ol a {
  display: flex;
}
.nav-breadcrumb ol img {
  width: 15px;
}

div.DraftEditor-root {
  max-height: 124px;
  overflow: auto;
}

.uniqueEditorWrapper {
  position: relative;
}

.uniqueEditorWrapper .DraftEditor-root {
  height: inherit;
  padding: 7px 86px 7px 12px;
  color: rgba(28,43,51,1);
  font-size: 1rem;
  border: 1px solid rgba(203,210,217,1);
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  max-height: calc(1.5em * 10) !important;
  .DraftEditor-editorContainer {
    height: 100% !important;
  }
}

.profileImage {
  width: 40px;
  height: 36px;
  background: #F4F6FF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  span {
    color: #D2D6E6;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
  }
}

.modalClose {
  cursor: pointer;
}

/**
 ** phone dropdown
 **/
.react-tel-input .form-control {
  width: 100% !important;
  border: 1px solid #EAEEF5 !important;
  border-radius: 8px !important;
  padding: 15px 14px 15px 60px !important;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 20px;
  color: #151D2C !important;
}

.form-select {
  border: none;
  background-color: transparent;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #151D2C;
  -webkit-appearance:none;
}
.form-select:focus {
  border-color: transparent;
  box-shadow: none;
  outline: none ! important;
}
.nav-link:focus {
  outline: none ! important;
}

@media screen and (min-width: 1400px) {
  .sa-question-layouted {
    width: 100% !important;
  }
}

#product-usecases {
  scroll-behavior: smooth;
}

footer {
  // padding: 3rem 0;
  display: grid;
  row-gap: 3rem;
  margin-top: -1px;
  padding: 40px 0;
  text-align: left;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  .container {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .footer-links {
    display: grid;
    grid-template-areas: "product legal";
    grid-template-columns: repeat(4, 1fr);
    .footer-links-content {
      display: flex;
      flex-direction: column;
      a {
        display: block;
        padding: 0.25ch 0;
        color: #2b2a35;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
      .footer-header {
        display: block;
        margin: 0 0 1rem;
        letter-spacing: 0;
        font: inherit;
        font-weight: bold;
      }
    }
  }
  @media screen and (max-width: 39.9375em) {
    .footer-links {
      display: grid;
      grid-template-areas: "product legal";
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .divider {
    grid-area: divider;
    height: 1px;
    margin-top: 14px;
    box-shadow: inset 0 1px 0 rgb(0 0 0 / 12%);
  }
  .footer-bottom {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .footer-left {
      display: flex;
      justify-content: center;
      flex-direction: column;
      p {
        margin-bottom: 0;
        font-size: 15px;
        margin-top: 10px;
        opacity: 0.69;
      }
    }
    .footer-right {
      display: flex;
      flex-direction: column;
      .right-upper {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-bottom: 20px;
        img {
          height: 30px;
        }
        a:nth-of-type(2) {
          margin: 0 20px;
        }
        .demo-btn {
          background-color: #000000;
          color: #ffffff;
          padding: 7px 27px;
          font-weight: 700;
          text-decoration: none;
        }
      }
      .right-lower {
        padding-top: 10px;
        border-top: 2px solid #E9E9EF;
        a {
          text-decoration: none;
          color: #000000;
          opacity: 0.69;
          margin: 0 20px;
          font-size: 14px;
        }
        a:first-of-type {
          margin-left: 0;
        }
        a:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 39.9375em) {
    .footer-bottom {
      flex-direction: column;
      .footer-left {
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 63.9375em) {
  footer {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    .footer-bottom {
      .footer-left {
        .logo {
          text-align: center;
          margin-bottom: 25px;
        }
        p {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .footer-right {
        p {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
          text-align: center;
          margin-top: 10px;
          margin-bottom: 25px;
        }
        .right-upper {
          justify-content: center;
          margin-bottom: 30px;
        }
        .right-lower {
          text-align: center;
          border-top: 1px solid #E9E9EF;
          padding-top: 20px;
          a {
            color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            margin: 0;
          }
          a:nth-of-type(2) {
            margin: 0 20px;
          }
        }
      }
    }
  }
}
