.footer {
  background-color: #5CDB94;
  a {
    color: #ffffff;
    text-decoration: none;
  }
  ul {
    padding-left: 0;
  }
}
