/* outfit-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/outfit-v6-latin-300.eot'); /* IE9 Compat Modes */
  src: url('./fonts/outfit-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/outfit-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/outfit-v6-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/outfit-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/outfit-v6-latin-300.svg#Outfit') format('svg'); /* Legacy iOS */
}
/* outfit-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/outfit-v6-latin-500.eot'); /* IE9 Compat Modes */
  src: url('./fonts/outfit-v6-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/outfit-v6-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/outfit-v6-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/outfit-v6-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/outfit-v6-latin-500.svg#Outfit') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-check-input:checked, .btn-primary {
  background-color: #4A60F4 !important;
  border-color: #4A60F4 !important;
}

.btn-danger {
  background-color: #E86060 !important;
  border-color: #E86060 !important;
}

.modal-footer {
  justify-content: space-between !important;
}

.calendly-inline-widget {
  height: 700px !important;
}

@media (max-width: 63.9375em) {
  .calendly-inline-widget {
    height: 840px !important;
    padding: 0 12px;
  }
}

.dropdownMenu {
  position: absolute;
  left: 0;
  width: 200px;
  background-color: white;
  border-radius: 4px;
  margin-top: 2px;
  z-index: 5;
  border: 1px solid transparent;
  max-height: 150px;
  overflow-y: auto;
  display: none;
}

.dropdownMenu.open {
  border-color: #ccc;
  display: block;
}

.dropdownMenu .dropdownItem {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownMenu .dropdownItem:hover {
  background-color: #f0f0f0;
}

.dropdownMenu .dropdownItem input {
  margin-right: 10px;
}

.dropdownMenu .dropdownItem.disabled {
  color: #999;
  cursor: not-allowed;
}

.dropdownMenu.above {
  bottom: 100%;
  margin-bottom: 2px;
  margin-top: 0;
}

.dropdownMenu.below {
  top: 100%;
}

.react-confirm-alert-body h1 {
  font-size: 20px;  /* adjust as needed */
}

.react-confirm-alert-button-group > button {
  background: #4E5FEA !important;
}

.react-datepicker {
  display: flex !important;
  gap: 20px !important;
  padding: 0 0 0 20px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day-names {
  color: #606770;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  width: 32px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.react-datepicker__day {
  font-weight: 500;
  color: #1c1e21 !important;
  height: 24px;
  line-height: 24px !important;
  margin-top: 4px !important;
  text-align: center !important;
  vertical-align: middle;
  width: 32px !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: 1px solid #dadde1 !important;
  color: #fff;
}

.react-datepicker-time__header {
  color: #1c1e21 !important;
  font-size: 14px !important;
  line-height: 26px;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #333;
}

.react-datepicker__current-month,
.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  color: #1877f2;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-month,
.react-datepicker__day--in-selecting-year,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #4E5FEA !important;
  color: #fff !important;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: inherit !important;
}
